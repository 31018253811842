@font-face { 
    font-family: "Omnes"; 
    src: url('../../assets/omnes.woff') format("woff"); 
} 

.nav-container{
    position:absolute;
    right: 1vw;
    top: 1.5vw;
}

.nav-link{
    font-family: Omnes;
    font-weight: 600;
    text-decoration: none;
    margin: 1.5vw;
    font-size: 1.7vw;
    letter-spacing: 0.15vw;
    color: #77b1a3; 
}

.nav-link:hover{
    color: #ffaa9f;
}

.nav-mobile{
    display: none;
}

@media screen and (max-width: 480px) {
    .nav{
        display: none;
    }
    .nav-mobile{
        display:block;
    }

/* Position and sizing of burger button */
.bm-burger-button {
    width: 40px;
    height: 30px;
    right: 84vw;
    top: 2.5vh;
    position: absolute;
  }
  
  /* Color/shape of burger icon bars */
  .bm-burger-bars {
    background: #5b4e4a;
  }
   
  /* Position and sizing of clickable cross button */
  .bm-cross-button {
    height: 30px;
    width: 30px;
    left: 55vw;
  }
  
  /* Color/shape of close button cross */
  .bm-cross {
    background: #5b4e4a;
  }
  
  /*
  Sidebar wrapper styles
  Note: Beware of modifying this element as it can break the animations - you should not need to touch it in most cases
  */
  .bm-menu-wrap {
    position: fixed;
    height: 100%;
    left: 0;

  }
  
  /* General sidebar styles */
  .bm-menu {
    background: #fff3e6;
    padding: 0;
    font-size: 1.5em;
    width: 70vw;
    
  }
    
  /* Wrapper for item list */
  .bm-item-list {
    padding: 1.75em;
  }
  
  /* Individual item */
  .bm-item {
    display: inline-block;
    color: #5b4e4a;
    margin-bottom: .75em;
    text-align: left;
    text-decoration: none;
    overflow-y: hidden;
    font-family: Omnes;
  }
  .bm-item:hover {
    color: #ffaa9f;
  }
  
}
  