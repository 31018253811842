.special-container{
    border:2px solid #5b4e4a;
    border-radius: 1vw;
    display: flex;
    width: 55vw;
    margin-bottom: 5vw;
    padding: 3vw 2vw;
    background-color: #fff6f0;
    color: #5b4e4a;
}

.special-col{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
    margin-left: 1.5vw;
    margin-right: 1.5vw;
    justify-content: center;
}

.special-title{
    font-size: 2vw;
    margin-bottom: 1vw;
    font-family: 'Varela Round', sans-serif; 
}

.special-text{
    font-size: 1.1vw;
    text-align: left;
    margin-bottom: 1vw;
}

.preview{
    width:25vw;
    margin-bottom: 1vw;
}

.special-link{
    color:#fff3e6;
    background-color: #9bcaea;      
    text-decoration: none;
    margin: 0vw 1.5vw;
    padding: .5vw 2vw;
    border-radius:10vw;
    font-size: 1.1vw;
}

.special-link:hover{
    background-color: #ffaa9f;  
}

.hidden-link{
    display: none;
}

.links{
    display: flex;
    justify-content: center;
}

@media screen and (max-width: 480px) {
    .special-container{
        width: 70vw;
        margin-bottom: 6vw;
        padding: 5vw 3vw;
        flex-direction: column;
    }
        
    .special-title{
        font-size: 1em;
        margin-bottom: 2vw;
        font-family: 'Varela Round', sans-serif; 
    }
    
    .special-text{
        font-size: .8em;
        text-align: left;
        margin-bottom: 2vw;
    }
    
    .preview{
        display: none;
    }
    
    .special-link{
        color:#fff3e6;
        background-color: #9bcaea;      
        text-decoration: none;
        padding: .5vw 3vw;
        border-radius:10vw;
        font-size: 1em;
        margin-top: 2vw;
    }
}