@font-face { 
    font-family: "Omnes"; 
    src: url('../../assets/omnes.woff') format("woff"); 
} 

.projects-background{
    display: flex;
    background-color: #fff3e6;
    min-height: 100vh;
}

.projects-title{
    letter-spacing: 0.15vw;
    margin-top: 6vw;
    font-size: 5.2vw;
    font-weight: 100;
    color: #5b4e4a;
}

.projects{
    display: flex;
    margin: 0 auto;
    font-family: Omnes;
    flex-direction: column;
    text-align: center;
}

@media screen and (max-width: 480px) {
    .projects-title{
        margin-top: 12vh;
        font-size: 2.5em;
        font-weight: 100;
        color: #5b4e4a;
    }
}