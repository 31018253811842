@font-face { 
    font-family: "Omnes"; 
    src: url('../../assets/omnes.woff') format("woff"); 
} 

.contact-background{
    display: flex;
    flex-direction: column;
    background-color: #fff3e6;
    min-height: 100vh;
}

.contact{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-height: 90vh;
}
.card-container{
    display: flex;
    justify-content: center;
}

.contact-link{
    position:relative;
    font-family: Omnes;
    text-decoration: none;
    color:#fff3e6;
    background-color: #9bcaea;  
    padding: .5vw 3vw;
    margin: 1.25vw;
    font-size: 2vw;
    border-radius:10vw;
    margin-top: 53vh;
}

.contact-link:hover{
    background-color: #ffaa9f;  
}

.card{
    height:40vh;
    margin-top: 35vh;
    position: absolute;
}

.table-top{
    height: 85%;
    width: 100%;
    position: absolute;
    bottom: 0;
    object-fit: cover;
    object-position: right top;
}

.table {
    height: 84%;
    width: 100%;
    bottom: 0;
    background: #ccb09e;
    position: absolute;
 }

 .table::after {
    position: absolute;
    width: 100%; 
    max-height: 100%; 
    content: '';
    background: inherit;
    top: 0; 
    bottom: 0; 
    right: 0; 
    left: 0; 
    transform-origin: top right;
    transform: skewY(1.25deg);
    border-top: 2px solid #5b4e4a;
 }

 .contact-mobile{
    display: none;
 }

 @media screen and (max-width: 480px) {
    .contact{
        display: none;
    }

    .contact-mobile{
        display: block;
        overflow: hidden;
    }

    .contact-background{
        display: flex;
        flex-direction: column;
        background-color: #fff3e6;
        height: 100vh;
        overflow: hidden;
    }

    .table {
        border-top: 2px solid #5b4e4a;
     }

    .table::after {
        display: none;
     }

    .table-top-mobile{
        width: 100vw;
        max-height: 70vh;
        position: absolute;
        bottom: 0;
        right: 0;
    }

    .card-mobile{
        width: 90vw;
        margin-top: 20vh;
        position: absolute;
    }
    .card-container-mobile{
        display: flex;
        justify-content: center;
    }
    .contact-link-mobile{
        position:relative;
        font-family: Omnes;
        text-decoration: none;
        color:#fff3e6;
        background-color: #9bcaea;  
        padding: 1vw 6vw;
        margin: 1.5vw;
        font-size: 2.2em;
        border-radius:10vw;
        margin-bottom: 5vh;
    }
    
    .contact-link-mobile:hover{
        background-color: #ffaa9f;  
    }

    .mobile-links{
        margin-top: 41vh;
        display: flex;
        flex-direction: column;
        text-align: center;
    }

 }