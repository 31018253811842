@font-face { 
    font-family: "Omnes"; 
    src: url('../../assets/omnes.woff') format("woff"); 
} 

.art-background{
    display: flex;
    background-color: #fff3e6;
    min-height: 100vh;
}

.art-title{
    font-family: Omnes;
    letter-spacing: 0.2vh;
    margin-top: 5vw;
    margin-left: 5vw;
    margin-bottom: 0;
    font-size: 10vh;
    font-weight: 100;
    color: #5b4e4a;
}

.art{
    display: flex;
    flex-wrap: wrap;
    font-family: Omnes;
    padding-bottom: 5vw;
}

@media screen and (max-width: 480px) {
    .art-title{
        margin-top: 10vh;
        font-size: 2.5em;
    }
}