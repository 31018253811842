@font-face { 
    font-family: "Omnes"; 
    src: url('../../assets/omnes.woff') format("woff"); 
} 

.about-background{
    display: flex;
    background-color: #fff3e6;
    min-height: 100vh;
}
.about{
    display: flex;
    justify-content: center;
    margin: 0 auto;
    margin-top: 6vw;
}

.blackboard-container{
    display: flex;
    justify-content: center;
    max-height: 65vh;
}

.blackboard{
    height:65vh;
    max-width: 85vw;
    position: absolute;
}

.select{
    display: flex;
    position: relative;
    color: #fff3e6;
    justify-content: center;
}

.selector{
    color: #fff3e6;
    font-size: 2vw;
    background-color: transparent;
    border: 0px;
    margin:1vw;
    margin-top: 3vw;
    margin-bottom: 1vw;
    font-family: 'Varela Round', sans-serif;     
}

.selector.show {
    color: #ffaa9f;
}

.selector.hide{
    color: #fff3e6;
    display: flex;
}

.selector.divider:hover{
    color: #fff3e6;
    cursor: default;
}

.selector:hover{
    cursor: pointer;
    color: #e78174;
}

.info{
    font-family: Omnes;
    display: flex;
    position: relative;
    color: #fff3e6;
    font-size: 1.25vw;
    height: 45vh;
    overflow-y: auto;
    overflow-x: hidden;
}

.classlist{
    list-style-type: none;
}

.info::-webkit-scrollbar{
    width: 0.5vw;
}

.info::-webkit-scrollbar-track {
    box-shadow: inset 0 0 1vw rgba(0, 0, 0, 0.3);
  }
   
.info::-webkit-scrollbar-thumb {
    background-color: #fff3e6;
    border-radius: 10vw;
  }

.bio{
    display: flex;
    flex-direction: column;
}

.classes{
    display: flex;
    flex-direction: column;
    margin: 0 auto;
}

.words{
    max-width: 60vw;
}
.show{
    display: flex;
}

.hide{
    display: none;
}

.bear{
    width:100vw;
    position: absolute;
    bottom: 0;
}

.about-mobile{
    display: none;
}

@media screen and (max-width: 480px) {
    .about{
        display: none;
    }

    .about-mobile{
        display: flex;
        justify-content: center;
        margin: 0 auto;
        margin-top: 10vh;    
    }

    .blackboard-container{
        max-height: 70vh;
    }
    
    .blackboard{
        width: 90vw;
    }
    
    .selector{
        font-size: 1.25em;
        margin:1vw;
        margin-top: 11vh;
        margin-bottom: 1vh;
    }
        
    .info{
        font-size: .8em;
        height: 35vh;
        overflow-y: auto;
        overflow-x: hidden;
        line-height: 1.5em;
    }
        
    .words{
        max-width: 65vw;
    }
}