@font-face { 
    font-family: "Omnes"; 
    src: url('../../assets/omnes.woff') format("woff"); 
} 

.artwork-background{
    display: flex;
    background-color: #fff3e6;
    min-height: 100vh;
}

.artwork-container{
    display: flex;
    padding-top: 10vw;
    align-self: flex-start;
    margin: 0 auto;
    font-family: Omnes;
    font-size: 1.3vw;
    color: #5b4e4a;
}

.artwork-title{
    font-size: 2vw;
    font-weight: 500;
    padding-bottom: 1vw;
    text-align: center;
}

.artwork-dims{
    font-style: italic;
    padding-bottom: 1vw;
    text-align: center;
}

.artwork-words{
    display: flex;
    flex-direction: column;
    margin-right: 3vw;
    width: 30vw;
    text-align: left;
}

.artwork-image{
    max-width: 55vw; 
    max-height: 70vh;
}

.artwork-image.true{
    display: none;
}

.artwork-animation{
    width: 55vw;
    max-height: 70vh;
}

.artwork-animation.false{
    display: none;
}

@media screen and (max-width: 480px) {
    .artwork-container{
        padding-top: 10vh;
        font-size: 1em;
        flex-direction: column;
        align-items: center;
    }
    
    .artwork-title{
        font-size: 2em;
        font-weight: 500;
        padding-bottom: 2vw;
    }
    
    .artwork-dims{
        font-style: italic;
        padding-bottom: 1vw;
        text-align: center;
    }
    
    .artwork-words{
        text-align: center;
        margin-right: 3vw;
        padding-bottom: 8vw;
        flex-direction: column;
        width: 90vw;
    }
    
    .artwork-image{
        max-width: 80vw; 
        max-height: 70vh;
    }
    
    .artwork-animation{
        width: 80vw;
        max-height: 70vh;
    }

}