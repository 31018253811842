@font-face { 
    font-family: "Omnes"; 
    src: url('../../assets/omnes.woff') format("woff"); 
} 

.menu-container{
    display: flex;
    max-width: 30vw;
    max-height: 25vw;
    margin-left: 10vw;
    margin-right: 0;
    margin-top: 4vw;
    margin-bottom: 0;
    flex-direction: column;
    text-align: center;
    color: #5b4e4a;
    align-items: center;
    padding: 0;
}

.artPiece{
    max-width: 22vw;
    max-height: 20vw;
    padding-bottom: 1vw;
}

.artSelect:hover{
    cursor: pointer;
    color: #ffaa9f;  
}

.artTitle{
    font-size: 1.8vw;
}

@media screen and (max-width: 480px) {
    .artTitle{
        font-size: .85em;
    }

    .artPiece{
        max-width: 25vw;
        max-height: 22vw;
    }
    .menu-container{
        max-width: 30vw;
        max-height: 25vw;
        margin-right: 0;
        margin-left: 7vw;
        margin-top: 10vw;
    }
}